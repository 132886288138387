
import Vue from "vue";
import { Component } from "vue-property-decorator";
import people, { People as PeopleType } from "@/store/people";

@Component
export default class People extends Vue {
  name = "People";

  get people(): { [k: string]: PeopleType } {
    return this.$i18n.locale === "en" ? people.en : people.zh;
  }

  get minNameWidth(): string {
    return this.$i18n.locale === "zh" ? "4.2rem" : "10rem";
    // return this.$vuetify.breakpoint.mobile ? "7rem" : "4.2rem";
  }
}
